import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Harus di ganti Incididunt consequat magna magna ullamco non proident mollit consequat consectetur do elit mollit aliqua. Sit esse qui cupidatat aliquip proident sit ex. Lorem et occaecat officia consectetur aliqua irure deserunt in Lorem cupidatat:`}</p>
    </PageDescription>
    <ol>
      <li parentName="ol">{`Unity in diversity, adaptive and cohesive in the way we serve people.`}</li>
      <li parentName="ol">{`Trustworthy, building products that people can trust.`}</li>
      <li parentName="ol">{`Considerate, awareness to serve our customers and ourselves well.`}</li>
      <li parentName="ol">{`Generous, serving our customers with great and graceful experiences.`}</li>
      <li parentName="ol">{`Whimsical, bringing joy and simple delights in every part of interaction and experiences.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      